import Utils from '../../utility'

const initialState = {
  vendorSlipData: [],
  order: 'DESC',
  sortField: 'name',
  offset: 1,
  take: 10,
  search: '',
  loading: true,
  vendorList:[]
}
export const vendorReducer = (state = initialState, action:any) => {
  switch (action.type) {
    case `${Utils.ActionName.VENDOR}`:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return { ...state }
  }
}
