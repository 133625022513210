const actionName = {
  RETURN:"RETURN",
  VENDOR:"VENDOR",
  USER_LIST: "USER_LIST",
  PROPERTY_OWNER: "PROPERTY_OWNER",
  STORE: "STORE",
  LOADING: "LOADING",
  WAREHOUSE: "WAREHOUSE",
  PRODUCTS: "PRODUCTS",
  LOADING_START:"LOADING_START",
  LOADING_END:"LOADING_END",
  CATEGORY: "CATEGORY",
  BRAND: "BRAND",
  BRAND_OWNER: "BRAND_OWNER",
  CUSTOMER: "CUSTOMER",
  WAREHOUSE_STOCK: "WAREHOUSE_STOCK",
  STORE_STOCK: "STORE_STOCK",
  WAREHOUSE_TO_STORE: "WAREHOUSE_TO_STORE",
  WAREHOUSE_TO_WAREHOUSE: "WAREHOUSE_TO_WAREHOUSE",
  STORE_TO_WAREHOUSE: "STORE_TO_WAREHOUSE",
  STORE_TO_STORE: "STORE_TO_STORE",
  GRN: "GRN",
  STORE_GRN: "STORE_GRN",
  WAREHOUSE_GRN: "WAREHOUSE_GRN",
  SKU_TIMELINE:"SKU_TIMELINE",
  RTV_STORE_STOCK:"RTV_STORE_STOCK",
  ORDERS:"ORDERS",
  CASHOUT: "CASHOUT",
  BILLER:"BILLER",
  PAYTM:"PAYTM",
  CREDIT:"CREDIT",
  COMMON:"COMMON"
};

export default actionName;
