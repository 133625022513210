import { ProductInterface } from "../Interface/ProductsInterface";

function RtvDiscardBarcode(e:any,setBarcode:any,products:any,setAddedProduct:any,addedProduct:any,filtered:any) {
  setBarcode(e.target.value.trim());

  let filteredNewArray: any = [];
  if (e.target.value.length > 0) {
    // eslint-disable-next-line
    const modifiedBarcode = e.target.value.replace(/^0+/, '');
filtered.map((item: any) => {
  if (
    item?.barcode === modifiedBarcode
  ) {
    let updatedQuantity = 1;
    if (item.barcode.includes(modifiedBarcode)) {
      updatedQuantity = item.quantity + 1;
    }
    const newObj = {
              id: item.id,
          name: item?.name,
          quantity: updatedQuantity,
          price: item.printedMrp ?? item.price,
          itemId:item?.itemId,
          barcode:item?.barcode,
          brandName: item?.brandName,
          sellingPrice: item.sellingPrice,
      productCostPrice: item.productCostPrice,
      expiryDate: item.expiryDate,
      weight: item?.weight,
      originalQuantity: item.originalQuantity,
    };
    filteredNewArray = [...filteredNewArray, newObj];
    setBarcode("")
    return newObj;
  }
})
// eslint-disable-next-line
products.filter((item: any) => {
const existingProduct = filtered?.find(
  (filteredItem: any) => filteredItem?.barcode === modifiedBarcode
);
  if (
    item?.product?.barcode === modifiedBarcode
  ) {
    let updatedQuantity = 1;
    if (existingProduct) {
      updatedQuantity = existingProduct.quantity + 1;
    }
    const newObj = {
      id: item.id,
          name: item.product.name,
          quantity: updatedQuantity,
          price: item.printedMrp,
          itemId:item?.product?.id,
          barcode:item?.product?.barcode,
          brandName: item?.product?.brands?.brandName,
          sellingPrice: item.sellingPrice,
      productCostPrice: item.productCostPrice,
      expiryDate: item.expiryDate,
      weight: item?.product?.description,
      originalQuantity: item?.quantity,
    };
    filteredNewArray = [...filteredNewArray, newObj];
    setBarcode("")
    return newObj;
  }else if(
  item?.product?.name.toLowerCase().replace(/ +/g, "") ===
    e.target.value.toLowerCase().replace(/ +/g, "") ||
  item?.product?.name
    .toLowerCase()
    .replace(/ +/g, "")
    ?.includes(e.target.value) === true){
      const productExists = filtered.find(
        (addedItem: any) => addedItem.barcode === item.product?.barcode
      );
      if(productExists){
        return;
      }
      const newObj = {
        id: item.id,
        name: item.product.name,
        quantity: 0,
        price: item.printedMrp,
        itemId:item?.product?.id,
        barcode:item?.product?.barcode,
        brandName: item?.product?.brands?.brandName
      };
      filteredNewArray = [...filteredNewArray, newObj];
    }
    return filteredNewArray
  });
    setAddedProduct([...addedProduct,...filteredNewArray]);
  }
}

export default RtvDiscardBarcode