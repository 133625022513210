import React, { useEffect } from 'react'
import LoadingComponent from '../../utility/LoadingComponent'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { TableHead, TablePagination } from '@mui/material';
import { useSelector } from 'react-redux';
import Utils from '../../utility';
import { useDispatch } from 'react-redux';
import { getVendorsList } from './action';

function VendorList() {
  const {vendorList,loading} = useSelector((state: any) => state.vendorReducer);
  const dispatch: any = useDispatch();
  const { offset,take } = useSelector((state: any) => state.vendorReducer);

  useEffect(()=>{
    dispatch(getVendorsList());
  },[])

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null,newPage: any) => {
    dispatch({
      type: Utils.ActionName.VENDOR,
      payload: { offset: newPage + 1  },
    });
    dispatch(getVendorsList());
  };

  const handleChangeRowsPerPage = (event: any) => {
    dispatch({
      type: Utils.ActionName.VENDOR,
      payload: { take: event.target.value, offset: 1 },
    });
    dispatch(getVendorsList());
  };

  // var serverTime = vendorSlipData.createdAt?.replace('T',' ');
  // let today = serverTime
  // today = today?.split('.')[0];

  return (
    <>
    {loading === true ? <LoadingComponent />:(
     <>
     <TableContainer style={{marginTop:"10px"}}>
   <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
   <TableHead>
       <TableRow>
         <TableCell><b>S.No</b></TableCell>
         <TableCell><b>Date</b></TableCell>
         <TableCell><b>Vendor Name</b></TableCell>
         <TableCell><b>Invoice No</b></TableCell>
         <TableCell><b>Invoice Amount</b></TableCell>
         <TableCell><b>Final Amount</b></TableCell>
         <TableCell><b>Paid Status</b></TableCell>
         <TableCell><b>Notes</b></TableCell>
       </TableRow>
     </TableHead>
     <TableBody>
     {vendorList?.map((a:any,i:any)=>(
       <React.Fragment key={a?.id}>
       <TableRow key={a?.id}>
           <TableCell style={{ width: 400 }}>
                {i+1}
           </TableCell>
           <TableCell style={{ width: 400 }}>
             {a?.createdAt?.replace('T',' ')?.split('.')[0]}
           </TableCell>
           <TableCell style={{ width: 400 }}>
             {a?.vendorName}
           </TableCell>
           <TableCell style={{ width: 400 }}>
             {a?.invoiceNumber}
           </TableCell>
           <TableCell style={{ width: 400 }}>
             {a?.invoiceAmount}
           </TableCell>
           <TableCell style={{ width: 400 }}>
             {a?.finalAmount}
           </TableCell>
           <TableCell style={{ width: 400 }}>
           {a?.vendorPaid===true?"Paid":"UnPaid"}
           </TableCell>
           <TableCell style={{ width: 400 }}>
           {a?.notes}
           </TableCell>
           </TableRow>
           </React.Fragment>
           ))}
           <div style={{right:0,position:"absolute"}}>
      <TablePagination
        rowsPerPageOptions={[10]}
        component="div"
        count={vendorList?.length}
        rowsPerPage={take}
        page={offset - 1}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        nextIconButtonProps={{ disabled: vendorList?.length<10 }}
      />
      </div>
     </TableBody>
     </Table>
     </TableContainer>
     </>
    )}
   </>
  )
}

export default VendorList