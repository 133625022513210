import Button from '@mui/material/Button';
import { useRef } from 'react';
import { useSelector } from 'react-redux';
import ReactToPrint from 'react-to-print';
import LoadingComponent from '../../utility/LoadingComponent';
import './VendorPrint.css'; // Create a CSS file for print-specific styles
import Logo from "./logo.jpeg";

function VendorPrint() {
  const componentRef = useRef<HTMLDivElement>(null);
  const { vendorSlipData } = useSelector((state: any) => state.vendorReducer);
  const sName = localStorage.getItem("storename");

  var serverTime = vendorSlipData.createdAt?.replace('T',' ');
  let today = serverTime
  today = today?.split('.')[0];

  return (
    <>
      {!vendorSlipData ? (
        <LoadingComponent />
      ) : (
        <>
          {/* Printable Content */}
          <div ref={componentRef} className="print-container">
            {/* Logo (visible only during printing) */}
            {/* <div className="print-logo"> */}
              <img
                src={Logo} // Replace with your logo path
                alt="Company Logo"
                // style={{
                //   width: '200px',
                //   height: '200px',
                //   objectFit: 'contain', // Ensures image fits within without distortion
                // }}
                className="print-logo"
              />
            {/* </div> */}

            {/* Title (changes during printing) */}
            <h1 className="print-title">Proof of Delivery Slip</h1>

            {/* Card-like Content */}
            <div className="print-card">
            <h3>Date: {today}</h3>
            <h3>StoreName: {sName}</h3>
              <h3>Slip ID: {vendorSlipData.id}</h3>
              <h3>Vendor Name: {vendorSlipData.vendorName}</h3>
              <h3>Invoice Number: {vendorSlipData.invoiceNumber}</h3>
              <h3>Invoice Amount: {vendorSlipData.invoiceAmount}</h3>
              <h3>Final Amount: {vendorSlipData.finalAmount}</h3>
              <h3>Notes: {vendorSlipData.notes}</h3>
            </div>
          </div>

          {/* Print Button */}
          <ReactToPrint
            trigger={() => (
              <Button
                style={{
                  position: 'absolute',
                  right: 0,
                  bottom: '20px',
                  marginRight: '50px',
                }}
                variant="outlined"
              >
                Print
              </Button>
            )}
            content={() => componentRef.current}
          />
        </>
      )}
    </>
  );
}

export default VendorPrint;