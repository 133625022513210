import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Input from "../../component/inputs/input";
import { Formik, Form } from "formik";
import { useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import PrintModal from "../../component/modal/PrintModal";
import { TextField, Checkbox, FormControlLabel, Autocomplete } from "@mui/material";
import { createVendorSlip, getVendorNames } from "./action";
import VendorPrint from "./vendorPrint";
import { useSelector } from "react-redux";

export default function Vendor() {
  const dispatch: any = useDispatch();
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    dispatch(getVendorNames());
  }, []);

  const { brandData } = useSelector((state: any) => state.vendorReducer);

  const initialValues = {
    vendorId: "",
    invoicenumber: "",
    invoiceAmount: "",
    finalamount: "",
    notes: "",
    vendorPaid: false,
  };

  const testform = () => {
    setConfirmOpen(true);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, { resetForm }) => {
        setIsDisabled(true);
        dispatch(
          createVendorSlip(
            values.vendorId,
            values.invoicenumber,
            values.invoiceAmount,
            values.finalamount,
            values.notes,
            values.vendorPaid
          )
        );
        resetForm();
      }}
    >
      {({ values, errors, handleChange, handleBlur, handleSubmit, setFieldValue, resetForm }) => (
        <>
          {confirmOpen && (
            <PrintModal
              children={<VendorPrint />}
              handleClose={() => {
                setConfirmOpen(false);
              }}
              open={confirmOpen}
            />
          )}
          <Form onSubmit={handleSubmit}>
            <div style={{ alignItems: "center", justifyContent: "center", display: "flex", height: "60vh" }}>
              <Card style={{ width: "70%", marginTop: "200px" }} sx={{ mt: 10 }}>
                <CardContent>
                  <Box sx={{ display: "flex", mt: 2 , width: "100%"}}>
                    <Autocomplete
                      sx={{ width: "100%" }}
                      options={Array.isArray(brandData) ? brandData : []}
                      getOptionLabel={(option: any) => option?.alias || ""}
                      onInputChange={(_, newInputValue) => setSearchTerm(newInputValue)}
                      onChange={(_, newValue) => setFieldValue("vendorId", newValue ? newValue.id : "")}
                      renderInput={(params) => <TextField {...params} label="Vendor" fullWidth />}
                    />
                  </Box>

                  <Box sx={{ display: "flex", mb: 2, mt: 2 }}>
                    <Input
                      value={values.invoicenumber}
                      name="invoicenumber"
                      type="text"
                      label="Invoice Number"
                      onChange={handleChange}
                      handleBlur={handleBlur}
                      error={errors.invoicenumber}
                    />
                  </Box>

                  <Box sx={{ display: "flex", mb: 2 }}>
                    <Input
                      value={values.invoiceAmount}
                      name="invoiceAmount"
                      type="number"
                      label="Invoice Amount"
                      onChange={handleChange}
                      handleBlur={handleBlur}
                      error={errors.invoiceAmount}
                    />
                  </Box>

                  <Box sx={{ display: "flex", mb: 2 }}>
                    <Input
                      value={values.finalamount}
                      name="finalamount"
                      type="number"
                      label="Final Amount"
                      onChange={handleChange}
                      handleBlur={handleBlur}
                      error={errors.finalamount}
                    />
                  </Box>

                  <Box sx={{ display: "flex", mb: 2 }}>
                    <TextField
                      label="Notes"
                      value={values.notes}
                      name="notes"
                      onChange={handleChange}
                      fullWidth
                      multiline
                      rows={3}
                      sx={{ mb: 2 }}
                    />
                  </Box>

                  <Box sx={{ display: "flex", mb: 2 }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.vendorPaid}
                          onChange={(e) => setFieldValue("vendorPaid", e.target.checked)}
                        />
                      }
                      label={values.vendorPaid ? "Paid" : "Unpaid"}
                      sx={{ color: values.vendorPaid ? "green" : "red", fontWeight: "bold" }}
                    />
                  </Box>

                  <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Button disabled={!values.invoicenumber || !values.finalamount || isDisabled} variant="outlined" type="submit" onClick={testform}>
                      Done
                    </Button>
                    <Button onClick={() => resetForm()} variant="outlined" sx={{ ml: 2 }}>
                      Cancel
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </div>
          </Form>
        </>
      )}
    </Formik>
  );
}
