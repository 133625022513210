import { useEffect, useState } from 'react';
import { io } from 'socket.io-client';
import navy_alarm from "./navy_alarmm.mp3";
import { useSelector } from 'react-redux';
import { getAllOrders } from '../../pages/onlineOrder/action';
import { useDispatch } from 'react-redux';

const SOCKET_URL = 'https://api.thenewshop.in/';

const useWebSocket = () => {
  const { storeId: getStoreId } = useSelector((state) => state.commonReducer);
  const dispatch = useDispatch();
  const newStoreId = localStorage.getItem('storeId')

  const [song] = useState(new Audio(navy_alarm));
  useEffect(() => {
    const socket = io(SOCKET_URL, {
      transports: ['websocket','polling'],
    });

    socket.on('connectionStatus', (message) => {
      console.log(message);
    });

    socket.on('orderPlaced', (data) => {
      const { storeId } = data;
      if (storeId == newStoreId) {
      // socket.on("orderPlaced", (data) => {
        song.play();
        setTimeout(() => {
          song.pause();
        }, 5000);
        dispatch(getAllOrders());
      // });
            }

    // return () => {
    //   if (socketInstance) {
    //     socketInstance.disconnect();
    //   }
    // };
    });
  }, []);

  return null;
};

export default useWebSocket;
