import { useState } from 'react';
import { Box } from '@mui/system';
import { makeStyles } from '@mui/styles';
import { NavLink } from 'react-router-dom';
import {Popover, Typography } from '@mui/material';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import HomeIcon from '@mui/icons-material/Home'; // Example icons
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import DashboardIcon from '@mui/icons-material/Dashboard';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import EventIcon from '@mui/icons-material/Event';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import StorefrontIcon from '@mui/icons-material/Storefront';
import SupportIcon from '@mui/icons-material/Support';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Logo from './logo.jpeg'; // Import your logo image
import ShiftOver from './ShiftOver';
import TransitionsModal from '../modal';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import StoreIcon from '@mui/icons-material/Store';

const useStyles = makeStyles(() => ({
  sidebar: {
    background: '#1a1a1a', // Sidebar background color
    width: '240px',
    height: '100vh',
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    position: 'fixed',
    transition: 'width 0.3s', // Smooth transition for width change
    overflowY: 'auto', // Enables vertical scrolling when content overflows
  overflowX: 'hidden', // Prevents horizontal scrolling
  scrollbarWidth: 'thin', // For modern browsers, makes the scrollbar thinner
  '&::-webkit-scrollbar': {
    width: '8px', // Custom width for Webkit browsers
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'yellow', // Yellow scrollbar thumb
    borderRadius: '4px',
    border: '2px solid black', // Black border around the thumb
  },
  '&::-webkit-scrollbar-thumb:hover': {
    backgroundColor: '#FFD700', // Slightly darker yellow on hover
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: 'black', // Black background for the scrollbar track
  },
  '&::-webkit-scrollbar-track:hover': {
    backgroundColor: '#222', // Dark gray on hover for the track
  },
  },
  collapsed: {
    width: '60px', // Adjust width when collapsed
    // overflow: 'hidden',
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '10px',
    position: 'relative',
    '& > img': {
      width: '100%',
      height: 'auto',
      objectFit: 'cover',
    },
  },
  toggleIcon: {
    position: 'absolute',
    top: '20px',
    right: '-30px', // Make sure it overlaps by placing it outside the sidebar width
    cursor: 'pointer',
    color: 'black',
    backgroundColor: '#d3d3d3',
    borderRadius: '50%',
    padding: '5px',
    zIndex: 2, // Ensure the chevron is above the sidebar for visibility
    transition: 'transform 0.3s', // Smooth transition when toggling
  },
  navLink: {
    color: 'white',
    textDecoration: 'none',
    padding: '10px 15px',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
    borderRadius: '8px',
    '&:hover': {
      background: '#333', // Hover background color
    },
  },
  activeNavLink: {
    background: '#FFD700', // Gold background for active link
    color: 'black',
  },
  icon: {
    marginRight: '15px',
  },
  supportLink: {
    color: 'white',
    padding: '10px 15px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '8px',
    marginBottom: '10px',
    '&:hover': {
      background: '#333',
    },
  },
  logoutButton: {
    color: 'white',
    padding: '10px 15px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '8px',
    '&:hover': {
      background: '#333',
    },
    cursor: 'pointer',
  },
  userContainer: {
    // color: 'white',
    // cursor:"pointer",
    // marginTop:"5px",
    // padding: '0px 15px',
    marginTop: 'auto', // Pushes the username to the bottom of the sidebar
    color: 'white',
    cursor: 'pointer',
    padding: '0px 15px',
    marginBottom:"20px"
  },
  shiftContainer:{
    marginBottom: '5px', // Add spacing between shift status and user field
    padding: '0px 15px',
    borderRadius: '8px',
    cursor: 'pointer',
    color:"white",
    marginTop: "5px"
  }
}));

const Sidebar = ({ collapsed, onToggle }) => {
  const [shiftOpen,setShiftOpen] = useState(false)
  const classes = useStyles();
  const userName = localStorage.getItem('username');
  // const {ongoingShiftId} = useSelector((state:any)=> state.loginReducer)
  const shiftStatusId = localStorage.getItem('shiftId');

  const handleLogoutClick = () => {
    setShiftOpen(true);
  };

  const [shiftClose,setShiftClose] = useState(false);

  const handleShiftModalClose = () => {
      setShiftOpen(false);
      setShiftClose(true)
  };

  const handleShiftCloseModal = () => {
    setShiftClose(false)
    setShiftOpen(false);
  }

  return (
    <Box className={`${classes.sidebar} ${collapsed ? classes.collapsed : ''}`}>
     {shiftOpen === true && (
              <TransitionsModal
              header="Shift Close"
              children={
                shiftOpen && (
                <ShiftOver onShiftCreated={handleShiftModalClose} />
                )
              }
              handleClose={handleShiftCloseModal}
              open={shiftOpen}
            />
    )}
      <Box className={classes.logoContainer}>
        <img src={Logo} alt="Logo" loading="lazy" />
        <Box className={classes.toggleIcon} 
        onClick={onToggle}>
          {collapsed ? <ChevronRightIcon /> : <ChevronLeftIcon />}
        </Box>
      </Box>
      <NavLink
        to="/billing"
        className={({ isActive }) =>
          isActive ? `${classes.navLink} ${classes.activeNavLink}` : classes.navLink
        }
      >
        <HomeIcon className={classes.icon} />
        {!collapsed && 'Billing'}
      </NavLink>

      <NavLink
        to="/return"
        className={({ isActive }) =>
          isActive ? `${classes.navLink} ${classes.activeNavLink}` : classes.navLink
        }
      >
        <EventIcon className={classes.icon} />
        {!collapsed && 'Return'}
      </NavLink>

      <NavLink
        to="/dashboard"
        className={({ isActive }) =>
          isActive ? `${classes.navLink} ${classes.activeNavLink}` : classes.navLink
        }
      >
        <DashboardIcon className={classes.icon} />
        {!collapsed && 'Dashboard'}
      </NavLink>

      <NavLink
        to="/credit"
        className={({ isActive }) =>
          isActive ? `${classes.navLink} ${classes.activeNavLink}` : classes.navLink
        }
      >
        <CreditCardIcon className={classes.icon} />
        {!collapsed && 'Credit'}
      </NavLink>

      <NavLink
        to="/online-order"
        className={({ isActive }) =>
          isActive ? `${classes.navLink} ${classes.activeNavLink}` : classes.navLink
        }
      >
        <ShoppingCartIcon className={classes.icon} />
        {!collapsed && 'Online Orders'}
      </NavLink>

      <NavLink
        to="/rtv"
        className={({ isActive }) =>
          isActive ? `${classes.navLink} ${classes.activeNavLink}` : classes.navLink
        }
      >
        <FileCopyIcon className={classes.icon} />
        {!collapsed && 'RTV'}
      </NavLink>

      <NavLink
        to="/discard"
        className={({ isActive }) =>
          isActive ? `${classes.navLink} ${classes.activeNavLink}` : classes.navLink
        }
      >
        <FileCopyIcon className={classes.icon} />
        {!collapsed && 'Discard'}
      </NavLink>

      <NavLink
        to="/vendor"
        className={({ isActive }) =>
          isActive ? `${classes.navLink} ${classes.activeNavLink}` : classes.navLink
        }
      >
        <StorefrontIcon className={classes.icon} />
        {!collapsed && 'Vendor'}
      </NavLink>

      <NavLink
        to="/vendorlist"
        className={({ isActive }) =>
          isActive ? `${classes.navLink} ${classes.activeNavLink}` : classes.navLink
        }
      >
        <StoreIcon className={classes.icon} />
        {!collapsed && 'VendorList'}
      </NavLink>

      <NavLink
        to="/cashout"
        className={({ isActive }) =>
          isActive ? `${classes.navLink} ${classes.activeNavLink}` : classes.navLink
        }
      >
        <CreditCardIcon className={classes.icon} />
        {!collapsed && 'Cashout'}
      </NavLink>

      <NavLink
        to="/audit"
        className={({ isActive }) =>
          isActive ? `${classes.navLink} ${classes.activeNavLink}` : classes.navLink
        }
      >
        <FileCopyIcon className={classes.icon} />
        {!collapsed && 'Audit'}
      </NavLink>
      <NavLink
        to="/shift"
        className={({ isActive }) =>
          isActive ? `${classes.navLink} ${classes.activeNavLink}` : classes.navLink
        }
      >
        <WorkOutlineIcon className={classes.icon} />
        {!collapsed && 'Shift'}
      </NavLink>
      <PopupState variant="popover" popupId="support-popup">
        {(popupState) => (
          <>
            <Box
              className={classes.supportLink}
              {...bindTrigger(popupState)}
            >
              <SupportIcon className={classes.icon} />
              {!collapsed && 'Support'}
            </Box>
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Typography sx={{ p: 2 }}>
                <b>24 Hour Helpline Number</b>
                <br /> +91 83687 69360
              </Typography>
              <hr />
              <Typography
                sx={{ p: 2 }}
                style={{ cursor: 'pointer' }}
                onClick={() => window.open('https://support.thenewshop.in/support/login')}
              >
                <b>Report other issues</b>
              </Typography>
            </Popover>
          </>
        )}
      </PopupState>

      <Box
        className={classes.logoutButton}
        onClick={handleLogoutClick}
      >
        <ExitToAppIcon className={classes.icon} />
        {!collapsed && 'Logout'}
      </Box>
      <Box className={classes.shiftContainer}>
  {collapsed ? (
    <>
      <Typography variant="body1"><b>Shift</b></Typography>
      <Typography variant="body2">{shiftStatusId}</Typography>
    </>
  ) : (
    <>
      <Typography variant="body2"><b>Shift:</b> {shiftStatusId}</Typography>
      {/* <Typography variant="body1"><b>UserName: {userName}</b></Typography> */}
    </>
  )}
</Box>


      <Box className={classes.userContainer}>
        <Typography variant="body2">User:</Typography>
        <Typography variant="body1"><b>{userName}</b></Typography>
      </Box>
    </Box>
  );
};

export default Sidebar;
