import Utils from '../../utility'

export const getVendorNames = () => {
  return (dispatch: any, getState: any) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    })
    const {
      order,
      sortField,
      offset,
      search,
    } = getState().productsReducer
    Utils.api.getApiCall(
      Utils.endPoints.vendor,
      `?order=${order}&offset=${offset}&sortField=${sortField}&search=${search}`,
      (respData: any) => {
        dispatch({
          type: Utils.ActionName.VENDOR,
          payload: {
            brandData: respData.data?.brandManager,
          },
        })
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        })
      },
      (error: any) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        })
        let { data } = error
        Utils.showAlert(2, data?.message)
      },
    )
  }
}

export const createVendorSlip = (
  vendorId:any,
  invoiceNumber: any,
  invoiceAmount:any,
  FinalAmt:any,
  notes:string,
  vendorPaid:boolean
) => {
  return (dispatch: any, getState: any) => {
    let dataToSend = {}
    dataToSend = {
      VendorId:vendorId,
      invoiceNumber,
      invoiceAmount:invoiceAmount.toString(),
      finalAmount:FinalAmt.toString(),
      notes,
      vendorPaid
    }
    Utils.api.postApiCall(
      Utils.endPoints.vendors + "/slip/create",
      dataToSend,
      (respData: any) => {
        dispatch({
          type: Utils.ActionName.VENDOR,
          payload: {
            vendorSlipData: respData.data,
          },
      })
        Utils.showAlert(1, 'Success')
      },
      (error: any) => {
        let { data } = error
        Utils.showAlert(2, data?.message)
      },
    )
  }
}

export const getVendorsList = () => {
  return (dispatch: any, getState: any) => {
      dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: true },
      })
      const {order,offset,take} = getState().vendorReducer;

      Utils.api.getApiCall(
          Utils.endPoints.vendors,
          `/slips?order=${order}&offset=${offset}&take=${take}`,
          (respData: any) => {
              dispatch({
                  type: Utils.ActionName.VENDOR,
                  payload: {
                      vendorList : respData.data,
                      loading: false,
                  },
              })
              dispatch({
                  type: Utils.ActionName.LOADING,
                  payload: { loading: false },
              })
          },
          (error: any) => {
              dispatch({
                  type: Utils.ActionName.LOADING,
                  payload: { loading: false },
              })
              let { data } = error
              if(data?.message === "" || !data) {
                  Utils.showAlert(2,"VendorSlip Get Error:")
              }else{
                  Utils.showAlert(2,"VendorSlip Get Error: " +  data?.message)
              }
          },
      )
  }
}