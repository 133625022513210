import { combineReducers } from 'redux'
import { productsReducer } from '../pages/billing/reducer'
import { returnReducer } from '../pages/return/reducer'
import { ordersReducer } from '../pages/onlineOrder/reducer'
import { cashoutNewReducer } from '../pages/audit/reducer'
import { loginReducer } from '../pages/login/reducer'
import { dashboardReducer } from '../pages/dashboard/reducer'
import { cashoutReducer } from '../pages/cashout/reducer'
import { paytmReducer } from '../pages/paytm/reducer'
import { creditsReducer } from '../pages/credit/reducer'
import { commonReducer } from '../component/hoc/reducer'
import { vendorReducer } from '../pages/vendor/reducer'
const rootReducer = (state: any, action: any) => {
  if (action.type === 'RESET_STORE') {
    return appReducer(undefined, action)
  }

  return appReducer(state, action)
}
const appReducer = combineReducers({
  productsReducer,
  returnReducer,
  ordersReducer,
  cashoutNewReducer,
  loginReducer,
  dashboardReducer,
  cashoutReducer,
  paytmReducer,
  creditsReducer,
  commonReducer,
  vendorReducer
})
export default rootReducer
