import Button from '@mui/material/Button';
import { useRef } from 'react';
import { useSelector } from 'react-redux';
import ReactToPrint from 'react-to-print';
import LoadingComponent from '../../utility/LoadingComponent';
import Logo from '../vendor/logo.jpeg'; // Update the path to your logo file
import './CashoutPrint.css'; // Import your CSS file

function CashoutPrint() {
  const componentRef = useRef<HTMLDivElement>(null); 
  const { addedTime } = useSelector((state: any) => state.cashoutReducer);
  const { addedReason } = useSelector((state: any) => state.cashoutReducer);
  const { addedCashout } = useSelector((state: any) => state.cashoutReducer);
  const { userDoneBy } = useSelector((state: any) => state.cashoutReducer);

  let serverTime = addedTime?.replace('T', ' ');
  let today = serverTime?.split('.')[0];

  return (
    <>
      {addedReason === "" || addedCashout === "" ? (
        <LoadingComponent />
      ) : (
        <>
          {/* Card (for display only) */}
          <div className="cashout-card">
            <h1>{today}</h1>
            <h3>CASHOUT OF RS.</h3>
            <p>{Math.round(addedCashout)} for {addedReason}</p>
            <h3>Done By:</h3>
            <p>{userDoneBy}</p>
          </div>

          {/* Print Button */}
          <ReactToPrint
            trigger={() => (
              <Button
                style={{
                  position: "absolute",
                  right: 0,
                  bottom: "20px",
                  marginRight: "50px",
                }}
                variant="outlined"
              >
                Print
              </Button>
            )}
            content={() => componentRef.current}
          />

          {/* Print content (hidden on screen) */}
          <div className="cash-print-container" ref={componentRef}>
            {/* Logo */}
            <div className="print-logo">
              <img src={Logo} alt="Company Logo" />
            </div>

            {/* Print-specific content */}
            <div className="print-content">
              <p>Date - {today}</p>
              <p>Cashout - ₹{Math.round(addedCashout)}</p>
              <p>Reason - {addedReason}</p>
              <p>Done By - {userDoneBy}</p>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default CashoutPrint;
